import { iconesMenu, rotas } from 'src/app/constantes'
import { AcaoMenu } from '../_base/layout/models/acaoMenu.enum'


export class MenuConfig {

  id:any;

  public get semSistemaConfigs(): any {
    return {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: [
        ]
      },
    }
  }

  public get contabilConfigs(): any {
    return {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: [
          {
            title: 'CONTABILIDADE',
            root: true,
            bullet: 'dot',
            icon:iconesMenu.contabil,
            page: '/' + rotas.painelContabil
          },
          {
            title: 'Selecionar Empresa',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-4',
            acao: AcaoMenu.EscolhaEmpresa
          },
          // {
          //   title: 'Selecionar Estabelecimento',
          //   root: true,
          //   bullet: 'dot',
          //   icon: 'flaticon2-grids',
          //   acao: AcaoMenu.EscolhaEstabelecimento
          // },
          {
            title: 'Cadastros',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-contract',
            submenu: [
              { title: 'Empresa', page: '/empresa' },
              { title: 'Plano de Contas', page: '/' + rotas.plano },
              { title: 'Histórico', page: '/' + rotas.historico },
              { title: 'Lançamento Padrão', page: '/' + rotas.lancamentoPadrao },
              { title: 'Contabilista', page: '/' + rotas.contabilista },
              { title: 'Assinante', page: '/' + rotas.assinante },
              { title: 'Fornecedor/Cliente', page: '/' + rotas.fornecedoresClientes },
            ]
          },
          {
            title: 'Movimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-files-and-folders',
            submenu: [
              { title: 'Lançamento', page: '/' + rotas.lancamento },
              { title: 'Implantação de Saldo Inicial', page: '/' + rotas.implantacaoSaldoInicial },
              { title: 'Trocar Conta', page: '/' + rotas.trocarConta },
              { title: 'Bloquear Período', page: '/' + rotas.bloquarPeriodo },
              { title: 'Lançamentos Caixa', page: '/' + rotas.caixaLancamento },
              { title: 'Renumerar Lançamento', page: '/' + rotas.renumerarLancamento },
              { title: 'Importação Arquivo Sped', page: '/' + rotas.spedEcd + '/' + rotas.retornosSped },
              { title: 'Depreciação de Bens', page: '/' + rotas.depreciacao }
            ]
          },
          {
            title: 'Conciliação Bancária',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-soft-icons-1',
            submenu: [
              { title: 'Banco', page: '/' + rotas.bancos },
              { title: 'Conta Corrente', page: '/' + rotas.contaCorrentes },
              { title: 'Extrato e importação', page: '/' + rotas.conciliacaoBancaria },
              { title: 'Vínculo', page: '/' + rotas.conciliacaoBancaria + '/' + rotas.conciliacaoBancariaVinculo }
            ]
          },
          {
            title: 'Relatórios',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-pie-chart-4',
            submenu: [
              { title: 'Impressos', page: '/' + rotas.relatorios + '/' + rotas.contabeisLista },
              { title: 'Balancete', page: '/' + rotas.relatorios + '/' + rotas.balanceteTela },
              { title: 'Razão', page: '/' + rotas.relatorios + '/' + rotas.razaoTela  },
              { title: 'DMPL', page: '/' + rotas.relatorios + '/' + rotas.dmpl },
              { title: 'Análise de Balanço', page: '/' + rotas.relatorios + '/' + rotas.analiseBalanco },
              // { title: 'Gerador Demonstração', page: '/' + rotas.relatorios + '/' + rotas.geradorDemonstracao }
            ]
          },
          {
            title: 'Rotinas Contábeis',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-cardiogram',
            submenu: [
              { title: 'Apuração de resultado', page: '/' + rotas.encerramento },
              { title: 'Sped ECD', page: '/' + rotas.spedEcd },
              // { title: 'Importar ECD', page: '/' }, // Pendente desenvolvimento
              // { title: 'Identificação e remuneração sócios', page: '/' } // Não foi feito - avaliar
              // { title: 'Importar Arquivo', page: '/' } // Não foi feito - avaliar necessidade (importar ECD prioridade)
            ]
          },
          {
            title: 'Bens',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-architecture-and-city',
            submenu: [
              { title: 'Grupos de Bens', page: '/' + rotas.gruposPatrimonio },
              { title: 'Bens', page: '/' + rotas.patrimonios }
            ]
          },
          {
            title: 'Tabelas do Sistema',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-3',
            submenu: [
              { title: 'Plano de Contas Referencial', page: '/' + rotas.listaContaReferencial },
              { title: 'Planos Modelo', page: '/' + rotas.listaPlanoModelo},
              { title: 'Município', page: '/' + rotas.municipio }
            ]
          }
        ]
      },
    }
  }

  public get folhaConfigs(): any {
    return {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: [
          // {
          //   section: {
          //     title:'FOLHA DE PAGAMENTO',
          //     icon: iconesMenu.contabil
          //   }
          // },
          {
            title: 'FOLHA DE PAGAMENTO',
            root: true,
            bullet: 'dot',
            icon: iconesMenu.folha,
            page: '/' + rotas.painelFolha
          },
          {
            title: 'Selecionar Empresa',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-4',
            acao: AcaoMenu.EscolhaEmpresa
          },
          {
            title: 'Selecionar Estabelecimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-grids',
            acao: AcaoMenu.EscolhaEstabelecimento
          },
          {
            title: 'Selecionar Movimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-calendar-4',
            acao: AcaoMenu.EscolhaDataMovimento
          },
          {
            title: 'Cadastros',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-contract',
            submenu: [
              { title: 'Empresa', page: '/empresa' },
              { title: 'Colaboradores', page: '/' + rotas.listaColaboradores },
              { title: 'Departamento', page: '/' + rotas.departamentos },
              { title: 'Cargos', page: '/' + rotas.cargos },
              { title: 'Lotação Tributária', page: '/' + rotas.lotacoesTributarias },
              { title: 'Horários (turnos)', page: '/' + rotas.turnos},
              { title: 'Rubricas', page: '/' + rotas.rubricas },
              { title: 'Linha de Transporte', page: '/' + rotas.linhaTransporte },
              { title: 'Contabilista', page: '/' + rotas.contabilista },
              { title: 'Certificado Digital', page: '/' + rotas.certificadoDigital },
              { title: 'Anuênio', page: '/' + rotas.anuenio },
              { title: 'Feriados', page: '/' + rotas.feriados },
            ]
          },
          {
            title: 'Movimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-files-and-folders',
            submenu: [
              { title: 'E-Social', page: '/' + rotas.eventosEsocial2 },
              //{ title: 'E-Social'}, usado para abrir numa nova página
              { title: 'Cálculos', page: '/' + rotas.calculoFolha },
              { title: 'Faltas', page: '/' + rotas.colaboradorComVinculo + '/' + rotas.faltas },
              { title: 'Lançamento VT', page: '/' + rotas.lancamentoVt },
              { title: 'Médias Salariais', page: '/' + rotas.calculoMedias },
              // { title: 'Funcionário VT', page: '/funcionario-vt' },
            ]
          },
          {
            title: 'Relatórios',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-pie-chart-4',
            submenu: [
              { title: 'Impressos', page: '/' + rotas.relatorios + '/' + rotas.folhaLista }
            ]
          },
          {
            title: 'Tabelas do Sistema',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-3',
            submenu: [
              { title: 'Município', page: '/' + rotas.municipio },
              { title: 'INSS', page: '/' + rotas.inss },
              { title: 'IRRF', page: '/' + rotas.irrf }
            ]
          }
        ]
      },
    }
  }

  public get fiscalConfigs(): any {
    return {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: [
          {
            title: 'ESCRITA FISCAL',
            root: true,
            bullet: 'dot',
            icon: iconesMenu.fiscal,
            page: '/' + rotas.painelFiscal
          },
          {
            title: 'Selecionar Empresa',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-4',
            acao: AcaoMenu.EscolhaEmpresa
          },
          {
            title: 'Selecionar Estabelecimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-grids',
            acao: AcaoMenu.EscolhaEstabelecimento
          },
          {
            title: 'Cadastros',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-contract',
            submenu: [
              { title: 'Empresa', page: '/empresa' },
              { title: 'Certificado Digital', page: '/' + rotas.certificadoDigital },
              { title: 'Fornecedor/Cliente', page: '/' + rotas.fornecedoresClientes },
              { title: 'Produtos', page: '/' + rotas.produtos },
              { title: 'Serviços', page: '/' + rotas.servicos },
              { title: 'CFOP', page: '/' + rotas.cfop }
            ]
          },
          {
            title: 'Movimento',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-files-and-folders',
            submenu: [
              { title: 'NFS-e', page: '/' + rotas.nfse },
              { title: 'Histórico busca NFS-e', page: '/' + rotas.nfse + '/' + rotas.resultadoBusca },
              { title: 'NF-e entrada', page: '/' + rotas.nfe + '/' + rotas.entrada },
              { title: 'NF-e saída', page: '/' + rotas.nfe + '/' + rotas.saida },
              { title: 'Importa nota', page: '/' + rotas.importanota },
              { title: 'Faturamento do SIMPLES', page: '/' + rotas.faturamentoSimples },
              { title: 'e-Cac Simples', page: '/' + rotas.ecacSimples },
              { title: 'Faturamento do Lucro Presumido', page: '/' + rotas.faturamentoLucroPresumido },
              { title: 'Importação NF-e/NFC-e', page: '/' + rotas.dfeImportacao }
            ]
          },
          {
            title: 'Contabilização',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-document',
            submenu: [
              { title: 'Definição', page: '/' + rotas.definicaoContabil },
              { title: 'Lançamento fiscal', page: '/' + rotas.lancamentoFiscal }
            ]
          },
          {
            title: 'Relatórios',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-pie-chart-4',
            submenu: [
              { title: 'Impressos', page: '/' + rotas.relatorios + '/' + rotas.fiscalLista }
            ]
          },
          {
            title: 'Tabelas do Sistema',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-menu-3',
            submenu: [
              { title: 'Anexos do Simples Nacional', page: '/' + rotas.anexo },
              { title: 'Municípios', page: '/' + rotas.municipio}
            ]
          }
        ]
      },
    }
  }

  public get gerenciadorConfigs(): any {
    return {
      header: {
        self: {},
        items: []
      },
      aside: {
        self: {},
        items: [
          {
            title: 'GERENCIADOR',
            root: true,
            bullet: 'dot',
            icon: iconesMenu.gerenciador,
            page: '/' + rotas.painelGerenciador
          },
          {
            title: 'Empresas',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.empresas,
          },
          {
            title: 'Lista de Contatos',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.listaContatos
          },
          {
            title: 'Eventos',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.eventos
          },
          {
            title: 'Honorários',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.honorarios
          },
          {
            title: 'Lançamento de Honorários',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.contasReceber
          },
          {
            title: 'Cobrança Bancária',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.cobrancaBancaria
          },
          {
            title: 'Conta Corrente',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.contaCorrentes
          },
          {
            title: 'Inadimplentes',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.inadimplentes
          },
          {
            title: 'Recebidos',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.pagamentosRecebidos
          },
          {
            title: 'Escritório Contábil',
            root: true,
            bullet: 'dot',
            icon: 'flaticon-menu-button',
            page: rotas.empresaContabil
          },
        ]
      },
    }
  }
}
