import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { BloqueiarPeriodo } from 'src/app/core/models/bloqueiarPeriodo'
import { DesbloqueiarPeriodo } from 'src/app/core/models/desbloqueiarPeriodo'
import { Empresa } from 'src/app/core/models/empresa/empresa'
import { ImplantacaoSaldoInicial } from 'src/app/core/models/implantacaoSaldoInicial'
import { PlanoContaAuxliar } from 'src/app/core/models/planoContaAuxliar'
import { Retorno } from 'src/app/core/models/retorno'
import { BaseService } from 'src/app/core/shared/services/base.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { MenuConfigService } from 'src/app/core/_base/layout/services/menu-config.service'

@Injectable({
  providedIn: 'root'
})

export class EmpresaService extends BaseService {
  baseUrl = environment.apiUrl

  constructor(
    protected http: HttpClient, protected variaveisContextoService: VariaveisContextoService,
    private menuConfigService: MenuConfigService) {
    super(variaveisContextoService)
  }

  pegarEmpresas(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas')
  }

  pegarEstabelecimentoExcluído():Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/excluidas')
  }

  pegarEmpresasInativas(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/inativas')
  }

  inativarEmpresa(id: number): Observable<Retorno<any>> {
    return this.http.patch<Retorno<any>>(this.baseUrl + 'v1/empresas/' + id + '/inativar', null)
  }

  ativarEmpresa(id: number): Observable<Retorno<any>> {
    return this.http.patch<Retorno<any>>(this.baseUrl + 'v1/empresas/' + id + '/ativar', null)
  }

  pegarEmpresaPorId(empresaId: number, data?: Date): Observable<Retorno<any>> {
    const params: string = data === null || data === undefined ? '' : '?data=' + data
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + empresaId + params)
  }

  pegarEmpresaAtual(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId)
  }

  pegarIndicadores(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/indicadores')
  }

  pegarAnexos(): Observable<Retorno<any[]>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/anexos')
  }

  pegarRegimesTributacao(): Observable<Retorno<any[]>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/regimes-tributacao')
  }

  pegarIrpjCsll(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/irpj-csll')
  }

  bloqueiarPeriodoListaEmpresas(bloquearPeriodo: BloqueiarPeriodo) {
    return this.http.post(this.baseUrl + 'v1/empresas/bloquear-periodo', bloquearPeriodo)
  }

  desbloqueiarPeriodoListaEmpresas(desbloquearPeriodo: DesbloqueiarPeriodo) {
    return this.http.post(this.baseUrl + 'v1/empresas/desbloquear-periodo', desbloquearPeriodo)
  }

  implantacaoSaldoInicial(implantacaoSaldoInicial: ImplantacaoSaldoInicial) {
    this.preencherEmpresaId()
    implantacaoSaldoInicial.empresaId = this.empresaId
    return this.http.post(this.baseUrl + 'v1/empresas/' + this.empresaId + '/implantacao-saldo-inicial', implantacaoSaldoInicial)
  }

  pegarSaldoInicial(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/saldo-inicial')
  }

  pegarEstabelecimentosEmpresaId(id: number): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + id + '/estabelecimentos')
  }

  pegarEstabelecimentosEmpresaIdComExcluidos(id: number): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + id + '/estabelecimentos-com-excluidos')
  }

  pegarEstabelecimentosPorEmpresaSelecionada(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.pegarEstabelecimentosEmpresaId(this.empresaId)
  }

  pegarContasAuxiliaresPorEmpresaSelecionada(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/contas-auxiliares')
  }

  salvarContasAuxiliaresPorEmpresaSelecionada(contas: PlanoContaAuxliar[]) {
    this.preencherEmpresaId()
    return this.http.post(this.baseUrl + 'v1/empresas/' + this.empresaId + '/contas-auxiliares', contas)
  }

  excluirEmpresa(id: number) {
    return this.http.delete(this.baseUrl + 'v1/empresas/' + id)
  }

  excluirInformacaoEmpresa(id: number, informacaoEmpresaId: number) {
    return this.http.delete(this.baseUrl + 'v1/empresas/' + id + '/informacoes-empresa/' + informacaoEmpresaId)
  }

  salvarEmpresa(empresa: Empresa) {
    this.preencheSistemaEmpresa(empresa)
    return this.http.post(this.baseUrl + 'v1/empresas', empresa)
  }

  alterarEmpresa(empresa: Empresa) {
    this.preencheSistemaEmpresa(empresa)
    return this.http.put(this.baseUrl + 'v1/empresas', empresa)
  }

  private preencheSistemaEmpresa(empresa: Empresa) {
    empresa.sistema = this.menuConfigService.pegarSistemaAberto()
  }

  pegarInformacoesEmpresaSelecionada(ano: number): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/informacoes-por-ano/' + ano)
  }

  pegarUltimoSpedGerado(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/pegar-ultimo-sped-gerado')
  }

  pegarFornecedorClientes(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId  + '/fornecedores-clientes')
  }

  salvarLogoEmpresa(id: number, imagemBase64: string): Observable<any> {
    const payload = {
      id: id,
      imagem: imagemBase64,
    };
    return this.http.put(this.baseUrl + 'v1/empresas/imagem', payload);
  }

}
