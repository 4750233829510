import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { Estabelecimento } from 'src/app/core/models/estabelecimento'
import { Retorno } from 'src/app/core/models/retorno'
import { Observable } from 'rxjs'
import { BaseService } from 'src/app/core/shared/services/base.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { MenuConfigService } from 'src/app/core/_base/layout'
import { Sistema } from 'src/app/core/_base/layout/models/sistema.enum'

@Injectable({
  providedIn: 'root'
})
export class EstabelecimentoService extends BaseService {

  baseUrl = environment.apiUrl

  constructor(private http: HttpClient, protected variaveisContextoService: VariaveisContextoService,
              private menuConfigService: MenuConfigService) {
    super(variaveisContextoService)
  }

  pegarEstabelecimentoPorId(estabelecimentoId: number, data?: Date): Observable<Retorno<any>> {
    const params: string = data === null || data === undefined ? '' : '?data=' + data
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/estabelecimentos/' + estabelecimentoId + params)
  }

  pegarEstabelecimentoNome(id: number): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/estabelecimentos/' + id + '/nome')
  }

  pegarEstabelecimentosCobranca(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/estabelecimentos/todosEstabelecimentos')
  }

  pegarTiposManifestacoes(): Observable<Retorno<any[]>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/notas-eletronicas/tipos-busca-nfe')
  }

  pegarEstabelecimentoEscritorio(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/estabelecimentos/escritorioContabil')
  }

  excluirEstabelecimento(id: number) {
    return this.http.delete(this.baseUrl + 'v1/estabelecimentos/' + id)
  }

  salvarEstabelecimento(estabelecimento: Estabelecimento) {
    // Verifica se endereco.numero é um número ou uma string
    if (estabelecimento.endereco.numero !== undefined && estabelecimento.endereco.numero !== null) {
      estabelecimento.endereco.numero = Number(estabelecimento.endereco.numero.toString().replace(/\D/g, ''));
    } else {
      // Define endereco.numero como null se estiver vazio ou não definido
      estabelecimento.endereco.numero = null;
    }

    this.preencheSistemaEstabelecimento(estabelecimento)
    return this.http.post(this.baseUrl + 'v1/estabelecimentos', estabelecimento)
  }

  alterarEstabelecimento(estabelecimento: Estabelecimento) {
    this.preencheSistemaEstabelecimento(estabelecimento)
    return this.http.put(this.baseUrl + 'v1/estabelecimentos', estabelecimento)
  }

  private preencheSistemaEstabelecimento(estabelecimento: Estabelecimento) {
      estabelecimento.sistema = this.menuConfigService.pegarSistemaAberto()
  }

  restaurarEstabelecimentoExcluido(estabelecimento: Estabelecimento) {
    return this.http.put(this.baseUrl + 'v1/estabelecimentos/' + estabelecimento.id + '/restauracao', estabelecimento)
  }

  pegarTodosEstabelecimentos(): Estabelecimento {
    return {
      id: 0,
      nome: 'Todos os estabelecimentos',
      nomeFantasia: '',
      empresaId: 0,
      cpfCnpj: '',
      telefone1: 'string',
      telefone2: 'string',
      inscricaoEstadual: 'string',
      inscricaoMunicipal: 'string',
      emails: [],
      nire: 'string',
      nomeJunta: 'string',
      matriz: false,
      tipo: 1,
      dataJunta: new Date(),
      lancamentos: null,
      sistema: Sistema.Contabil,
      indicadorAtividade: 1,
      dadosEstab: {
        cnaePrep: 'string',
        aliqGilrat: {
          aliqRat: 0,
          fap: 0,
          procAdmJudRat: {
            nrProc: 'string',
            codSusp: 'string',
            tpProc: 0
          },
          procAdmJudFap: {
            nrProc: 'string',
            codSusp: 'string',
            tpProc: 0
          }
        },
        infoCaepf: {
          tpCaepf: 0
        },
        infoObra: {
          indSubstPatrObra: 0
        },
        infoTrab: {
          infoApr: {
            nrProcJud: 'string',
            infoEntEduc: {
              nrInsc: 'string'
            }
          },
          infoPCD: {
            nrProcJud: 'string'
          }
        }
      },
      idePeriodo: {
        iniValid: new Date(),
        fimValid: new Date()
      },
      endereco: {
        id: 0,
        codigoIBGE: 'string',
        logradouro: 'string',
        numero: 0,
        complemento: 'string',
        bairro: 'string',
        cep: 'string',
        exterior: false,
        paisExterior: 0,
        nomeCidadeExterior: 'string'
      },
      ultimoNsu: 0,
      tipoBuscaNFe: 0,
      buscaAutomaticaNfse: false,
      vencimentoGerenciador: 0,
      vencimentoProximoMes: false,
      formaPagamentoGerenciador: 0,
      percentualJuros: 0,
      percentualMulta: 0,
      whatsapp: '',
      nomeWhatsapp: '',
      subscribe: (arg0: { next: (todosEstabelecimentos: any) => void; error: (error: any) => void }): unknown => {
        // Implementation of subscribe method
        return;
      }
    }
  }
}
